.rootContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.contentContainer {
  max-width: 23rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 1rem;
  padding: 1rem 0;
}

.containerAviso {
  width: calc(100% - 4px);
  align-self: center;
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 4px;
  border-radius: 0.5rem;
  background: white;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.containerAviso .containerMensagem {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.5rem;
}

.containerAviso .containerMensagem .image {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 9999px;
  border: 2px solid var(--sofisa-warning-blue);
}

.containerAviso .containerMensagem .mensagem {
  color: var(--sofisa-text-dark);
  font-size: var(--sofisa-text-size-small);
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.25px;
}

.containerConsentimento {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  padding: 1px;
  margin-top: 3rem;
}

.containerCompartilhamento {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.tituloOperacao {
  color: var(--sofisa-text-dark-green);
  font-family: 'Nunito';
  font-size: var(--sofisa-text-size-large);
  font-weight: 700;
}

.containerDados {
  width: calc(100% - 4px);
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 4px;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.containerTituloSecao {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  box-sizing: border-box;
  padding-left: 1rem;
  background-color: var(--sofisa-weak-blue);
}

.containerTituloSecao.dadosObrigatorios > img {
  width: 1.5rem;
  height: 1.5rem;
  box-sizing: border-box;
  padding: 0.2rem;
  border-radius: 0.5rem;
  border: thin solid var(--sofisa-disabled-green);
}
.containerTituloSecao > span {
  font-family: 'Nunito';
  color: var(--sofisa-direto-green);
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.1px;
}

.checkboxNaoSelecionado {
  width: 1.5rem;
  height: 1.5rem;
  box-sizing: border-box;
  border: thin solid var(--sofisa-light-blue);
  border-radius: 0.5rem;
  background: white;
}

.resumoBotoes{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.containerDados .detalhesDadosCompartilhados {
  margin-top: 1.5rem;
  margin-inline: 1rem;
  margin-bottom: 2rem;
}
.detalhesDadosCompartilhados .recursosACompartilhar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  margin-bottom: 2.5rem;
}
.detalhesDadosCompartilhados .recursosACompartilhar > span {
  color: var(--sofisa-text-dark);
  font-size: var(--sofisa-text-size-medium);
  font-weight: 700;
  line-height: 130%;
}
.detalhesDadosCompartilhados .recursosACompartilhar > div {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.detalhesDadosCompartilhados .recursosACompartilhar .recurso {
  display: flex;
  gap: 0.75rem;
}

.detalhesDadosCompartilhados .recursosACompartilhar .recurso > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.detalhesDadosCompartilhados .recursosACompartilhar .recurso .tituloRecurso {
  font-family: Nunito;
  font-size: var(--sofisa-text-size-medium);
  font-weight: 600;
  letter-spacing: 0.15px;
}
.detalhesDadosCompartilhados .recursosACompartilhar .recurso .descricaoRecurso {
  font-family: Nunito;
  font-size: var(--sofisa-text-size-small);
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.1px;
}

.detalhesDadosCompartilhados .dadosACompartilhar {
  color: var(--sofisa-text-dark);
  font-size: var(--sofisa-text-size-medium);
  font-weight: 700;
  line-height: 130%;
  text-align: left;
}

.containerResumoDados {
  width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  margin-top: 4px;
  border-radius: 0.5rem;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.containerResumoDados .containerTituloResumo {
  font-family: Nunito;
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  box-sizing: border-box;
  margin-left: 1rem;
  font-size: 16px;
  font-weight: 700;
}

.containerResumoDados .resumoTituloDados {
  font-family: inherit;
  margin-top: 1rem;
  margin-left: 1rem;
  color: var(--sofisa-text-dark);
  font-size: 14px;
  font-weight: 700;
  line-height: 130%;
  text-align: left;
}

.containerResumoDados .resumoDadosValores {
  margin-left: 1rem;
  color: var(--sofisa-text-dark);
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}

.avisoResumo {
  margin: 1rem;
  margin-left: 4px;
  color: var(--sofisa-text-dark);
  font-size: 16px;
  font-weight: 700;
  line-height: 21.82px;
  text-align: left;
}

.avisoResumo > span {
  font-size: 18px;
  line-height: 24.55px;
}

.redirectCompartilhamentoText{
  color: #7E7E7E;
  width: 80vw;
  max-width: 30rem;
  margin-bottom: 0.5rem;
}

.redirectCompartilhamentoImage{
  color: #7E7E7E;
  width: 80vw;
  max-width: 30rem;
  margin-bottom: 0.5rem;
}

.containerRedirectFooter {
  margin-top: 10rem;
}

.containerPermissoes {
  margin-top: 1rem;
}

.containerProdutos {
  display: flex;
  margin: 1rem 0;
  padding-right: 2rem;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 1rem;
}

.containerProdutos > div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 1rem;
  border-left: 0.5rem solid var(--entidade-destaque);
}

.containerProdutos input[type='checkbox'] {
  margin-left: 3rem;
  transform: scale(1.5);
}

.linhaAzul {
  height: 100%;
}

.containerSubmit {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.accordion {
  display: flex;
  margin-top: 1.5rem;
  flex-direction: column;
  gap: 1.5rem;
}
.accordionItem {
  flex-grow: 1;
}
.accordionButton {
  width: 100%;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}
.accordionButtonResumo {
  width: 90%;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 1rem;
}

.accordionButtonResumo[aria-expanded='false'] > img {
  transform: rotate(180deg);
}

.accordionButton > span {
  font-family: 'Nunito';
  font-size: var(--sofisa-text-size-medium);
  font-weight: 600;
  line-height: 170%;
  letter-spacing: 0.15px;
}
.accordionButton[aria-expanded='false'] > img {
  transform: rotate(180deg);
}
.accordionPanel {
  padding-top: 1rem;
  padding-right: 1rem;
  padding-left: 2rem;
  padding-bottom: 1.5rem;
  text-align: left;
  animation: fadein 0.3s ease-in;
}
.accordionPanel li {
  color: var(--sofisa-text-gray);
  font-size: var(--sofisa-text-size-small);
  font-weight: 400;
  line-height: 150%;
}
@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
