.background {
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: var(--sofisa-dark-green);
}

.background > img {
  height: 2rem;
}
