.rootContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.loadingContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  gap: 1rem;
  background-color: white;
  font-size: 1.2rem;
}
