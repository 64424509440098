.rootContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.contentContainer {
  max-width: 21rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 1rem;
  padding: 1rem 0;
}

.containerToken {
  max-width: 45rem;
  min-width: 15rem;
  width: 80vw;
  align-self: center;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.inputTokenContainer{
  padding: 1rem
}

.containerToken .detalhesToken {
  margin-top: 1.5rem;
  margin-inline: 1rem;
}

.containerTokenCompartilhamento {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.containerBotoes{
  display: flex;
  gap: 10rem;
  width: auto;
  margin-top: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  align-items: center;
  text-align: center;
  overflow: hidden;
  flex-direction: row;
  justify-content: space-between;
}

.containerBotoes > .avancar{
  text-align: center;
  width: 10rem;
}

.containerTokenTitulo {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  box-sizing: border-box;
  padding-left: 1rem;
  background-color: var(--sofisa-weak-blue);
}

.containerTokenHeader{
  width: 100%;
  box-sizing: border-box;
  border: #D5D5D5B2;
  font-family: 'Nunito';
  color: var(--sofisa-direto-green);
  font-weight: 400;
  line-height: 24.55px;
  font-size: 18px;
  letter-spacing: 0.1px;
}

.detalhesToken {
  background-color: #FCF8E3;
  border: solid;
  border-color: #FAEBCC;
  color: #8A6D3B;
  display: flex;
  border-radius: 0.5rem;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 1rem
}

.instrucaoToken > * {
  font-size: var(--sofisa-text-size-medium);
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.inputToken {
  width: 100%;
}

.avisoAtivacaoToken {
  font-size: var(--sofisa-text-size-small);
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin-top: 3rem;
}

.avisoAtivacaoToken > span {
  color: var(--sofisa-text-dark-green);
  font-size: var(--sofisa-text-size-small);
  font-weight: 700;
  line-height: 150%;
}

.containerAviso {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  background: white;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin-top: 0.5rem;
}
.containerAviso .containerMensagem {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.5rem;
}
.containerMensagem .image {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 9999px;
}
.containerAviso .containerMensagem .image {
  border: 2px solid var(--sofisa-warning-blue);
}
.containerAviso .containerMensagem .mensagem {
  color: var(--sofisa-warning-blue);
  font-size: var(--sofisa-text-size-medium);
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.1px;
}

.containerTokenBloqueado {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  background: white;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin-top: 0.5rem;
}
.containerTokenBloqueado .containerMensagem {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
}
.containerTokenBloqueado .containerMensagem .image {
  margin-right: 1rem;
  border: 2px solid var(--sofisa-danger);
}
.containerTokenBloqueado .containerMensagem .mensagem > :first-child {
  color: var(--sofisa-danger);
  font-size: var(--sofisa-text-size-medium);
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.1px;
  margin-bottom: 0.2rem;
}
.containerTokenBloqueado .containerMensagem .mensagem > :last-child {
  color: var(--sofisa-text-dark);
  font-size: var(--sofisa-text-size-small);
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.25px;
}
.containerTokenBloqueado .containerMensagem .botaoFechar {
  align-self: flex-start;
  cursor: pointer;
}

.linhaAviso {
  height: 100%;
}

.contentContainer .botoes {
  margin-top: 0.5rem;
}

.contentContainer .botoes,
.contentContainer .botoes > * {
  width: 100%;
}
