.rootContainer {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.rootContainer > img {
  width: 1.5rem;
  height: 1.5rem;
}

.rootContainer > span {
  color: var(--sofisa-light-blue);
  font-size: var(--sofisa-text-size-small);
  font-weight: 700;
  line-height: 200%;
  letter-spacing: 0.15px;
}
