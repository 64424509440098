.labelInputContainer {
  display: flex;
  flex-direction: column;
}

.label {
  color: var(--sofisa-light-blue);
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.input {
  height: 3rem;
  background-color: var(--sofisa-background-weak-blue);
  border-radius: 0.25rem;
  border: none;
  outline: none;
  padding: 0 0.5rem;
}

.mensagemErro {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
  align-items: center;
}
.mensagemErro > img {
  width: 1rem;
  height: 1rem;
}
.mensagemErro > span {
  color: var(--sofisa-text-danger);
  font-size: var(--sofisa-text-size-small);
  font-weight: 400;
}

.inputInvalido .input {
  border: thin solid var(--sofisa-danger);
}
.inputInvalido .label {
  color: var(--sofisa-text-weak-danger);
}
