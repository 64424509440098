.rootContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0 1rem;
}

.levelContainer {
  align-self: flex-start;
}

.containerBancos {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
}
.containerBancos .banco {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.containerBancos .banco > img {
  height: 1.5rem;
  margin-left: 1rem;
}
.containerBancos .banco > span {
  color: var(--sofisa-text-dark);
  font-size: var(--sofisa-text-size-medium);
  font-family: Nunito;
  font-weight: 700;
}

.transitionCard {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: var(--sofisa-weak-blue);
  border-radius: 0.5rem;
  padding: 1rem;
}

.transitionCard > :first-child {
  margin-left: 0.3rem;
}

.transitionCard .texto {
  font-size: var(--sofisa-text-size-small);
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.25px;
}
