button {
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  height: 3rem;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 1.43px;
}

button.confirmacao {
  background-color: var(--sofisa-button-background-confirm);
  color: var(--sofisa-button-text-confirm);
  border: thin solid var(--sofisa-button-background-confirm);
}

button.avancar {
  background-color: #0CB498;
  color: #FFFFFF;
  width: 100%;
}

button.cancelamento {
  background-color: var(--sofisa-button-background-cancel);
  color: var(--sofisa-button-text-cancel);
  border: thin solid var(--sofisa-button-text-cancel);
}

button.voltar {
  background-color: transparent; 
  border: none; 
  cursor: pointer; 
  display: flex; 
  align-items: center; 
  justify-content: space-between;
  padding: 10px;
  color: #9cb1c6;
  width: 7rem;
  flex-direction: row-reverse;
  font-size: 14px;
}

button.voltar img {
  margin-left: 8px;
}

button.avisoConfirmacao {
  background-color: #FAAE29; 
  border: none; 
  cursor: pointer; 
  display: flex; 
  align-items: center; 
  justify-content: space-between;
  padding: 10px;
  color: #000000;
  width: 7rem;
  flex-direction: row-reverse;
  font-size: 14px;
}

button.avisoCancela {
  background-color: #FFFF; 
  border: #FAAE29; 
  cursor: pointer; 
  display: flex; 
  align-items: center; 
  justify-content: space-between;
  padding: 10px;
  color: #000000;
  width: 7rem;
  flex-direction: row-reverse;
  font-size: 14px;
}

button:disabled {
  background-color: dimgray;
  cursor: no-drop;
}
