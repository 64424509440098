:root {
  --sofisa-button-background-cancel: white;
  --sofisa-button-text-cancel: #005c4b;
  --sofisa-button-background-confirm: #005c4b;
  --sofisa-button-text-confirm: white;
  --sofisa-dark-green: #00352b;
  --sofisa-direto-green: #00a88f;
  --sofisa-light-gray: #d0deeb;
  --sofisa-green: #00473a;
  --sofisa-disabled-green: #b2e1dd;
  --sofisa-text-weak-danger: #920000;
  --sofisa-text-danger: #b00000;
  --sofisa-danger: #e00000;
  --sofisa-background-weak-blue: #e8edf3;
  --sofisa-light-blue: #6f89a4;
  --sofisa-warning-blue: #0464c8;
  --sofisa-weak-blue: #f1f5f8;
  --sofisa-text-dark: #131d29;
  --sofisa-text-dark-green: #003325;
  --sofisa-text-light-green: #018e74;
  --sofisa-text-gray: rgba(0, 0, 0, 0.66);
  --sofisa-text-size-large: 18px;
  --sofisa-text-size-medium: 14px;
  --sofisa-text-size-small: 12px;
}
