.rootContainer {
  display: flex;
  flex-direction: column;
}

.containerMensagem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.titulo {
  font-size: 1.6rem;
}

.subtitulo {
  font-size: 1.2rem;
}
