.container {
  width: 100%;
  display: flex;
  margin-bottom: 1.5rem;
}

.container > span {
  display: flex;
}

.etapa {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.etapa .indice {
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  color: white;
  margin-inline: 0.5rem;
  background-color: var(--sofisa-light-gray);
}

.etapa .nome {
  position: absolute;
  top: 2.5rem;
  color: var(--sofisa-light-gray);
  font-size: var(--sofisa-text-size-small);
  font-weight: 500;
  letter-spacing: 0.4px;
  text-align: center;
  margin-top: 0.25rem;
}

.container .separador {
  width: 5.5rem;
  height: 0.25rem;
  background-color: var(--sofisa-light-gray);
  margin-top: 1.125rem;
}

.etapa.atual .indice {
  background-color: var(--sofisa-direto-green);
}
.etapa.atual .nome {
  color: var(--sofisa-direto-green);
}
