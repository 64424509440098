.rootContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.contentContainer {
  width: 21rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 1rem;
  padding: 1rem 0;
}

.cardOperacao {
  width: 100%;
  min-height: 21rem;
  border-radius: 8px;
  box-shadow:
    0px 8px 16px -1px rgba(0, 0, 0, 0.25),
    0px 2px 4px -1px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  padding: 1rem;
}

.cardOperacao > div:not(:last-child) {
  margin-bottom: 1rem;
}

.tituloOperacao {
  color: var(--sofisa-text-dark-green);
  font-family: Nunito;
  font-weight: 700;
}

.containerTituloSecao {
  color: var(--sofisa-text-dark);
  font-family: Nunito;
  font-size: var(--sofisa-text-size-medium);
  font-weight: 700;
  line-height: 150%;
}

.containerTituloSecao ~ * {
  font-size: var(--sofisa-text-size-small);
  font-weight: 400;
  line-height: 150%;
}

.containerValorPagamento {
  margin-bottom: 0.3rem;
}
.valorPagamento {
  font-size: var(--sofisa-text-size-medium);
  font-weight: 700;
  line-height: 150%;
}
.moedaPagamento {
  margin-right: 0.2rem;
  font-weight: 400;
}
.valorSignificante {
  color: var(--sofisa-text-light-green);
  font-size: 150%;
  font-weight: 400;
}
.valorFracionado {
  position: relative;
  top: -0.3rem;
  left: 0.2rem;
  font-weight: 400;
}

.containerSubmit {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.containerAviso {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  background: white;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin-top: 0.5rem;
}
.containerAviso .containerMensagem {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.5rem;
}
.containerAviso .containerMensagem .image {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 9999px;
  border: 2px solid var(--sofisa-warning-blue);
}
.containerAviso .containerMensagem .mensagem {
  color: var(--sofisa-warning-blue);
  font-size: var(--sofisa-text-size-medium);
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.1px;
}

.containerTempoLimite {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  background: white;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin-top: 0.5rem;
}
.containerTempoLimite .containerTempoLimiteMensagem {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.5rem;
}
.containerTempoLimite .containerTempoLimiteMensagem .image {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.containerTempoLimite .containerTempoLimiteMensagem .mensagem {
  color: #FFBB33;
  font-size: var(--sofisa-text-size-medium);
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.1px;
}

.linhaAzul {
  height: 100%;
}

.selecaoContaOrigem {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.selecaoContaOrigem > div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.selecaoContaOrigem .inputContaOrigem {
  display: flex;
}
.selecaoContaOrigem .inputContaOrigem > input[type='radio'] {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  accent-color: var(--sofisa-light-blue);
}
.selecaoContaOrigem .inputContaOrigem > input[type='radio']:not(:checked) {
  appearance: none;
  border: thin solid var(--sofisa-light-blue);
  border-radius: 9999px;
}
.selecaoContaOrigem .dadosContaOrigem > *:first-child {
  color: var(--sofisa-text-dark);
  font-family: 'Nunito';
  font-size: var(--sofisa-text-size-medium);
  font-weight: 700;
  line-height: 150%;
}
.selecaoContaOrigem .dadosContaOrigem > *:not(:first-child) {
  font-size: var(--sofisa-text-size-small);
  font-weight: 400;
  line-height: 158%;
}
.selecaoContaOrigem .dadosContaOrigem > *:last-child {
  color: rgba(0, 0, 0, 0.5);
}
