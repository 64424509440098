body.active_modal {
    overflow-y: hidden;
  }
  
  .btn_modal_confirm {
    padding: 10px 20px;
    background-color: #FAAE29;
    display: inline-block;
    margin: 1rem;
    width: 15rem;
    font-family: Nunito;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    font-size: 18px;
  }
  
  .descriptionSpan{
    font-weight: bold;
    color: #696969;
    font-family: Nunito;
    position: relative;
    line-height: 24px;
    size: 16px;
  }
  
  .btn_modal_cancel {
    padding: 10px 20px;
    border: solid;
    background-color: #FFFFFF;
    border-color: #FAAE29;
    display: flex;
    margin: 1rem;
    width: 15rem;
    font-family: Nunito;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    font-size: 18px;
  }
  
  .btn_container{
    display: flex;
    flex-direction: row-reverse;
    margin: auto;
    width: auto;
    font-family: Nunito;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    font-size: 18px;
    justify-content: center;
  }
  
  .title{
    font-family: Nunito;
    position: relative;
    font-weight: 700;
    line-height: 27.28px;
  }
  
  .description{
    color: #696969;
    font-family: Nunito;
    position: relative;
    font-weight: 400;
    line-height: 24px;
    size: 16px;
  }
  
  .modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
  }
  
  .overlay {
    background: rgba(49,49,49,0.8);
  }
  .modal_content {
    position: absolute;
  
    text-align: center;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #FFFFFF;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
  }
  
  .close_modal {
    position: relative;
  }