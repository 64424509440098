.exchangeContainer {
  width: min-content;
}

.exchangeContainer > button {
  background: none;
  width: max-content;
  height: 0.5rem;
}

.exchangeContainer > button > img {
  width: 1rem;
  height: 15px;
}

.exchangeContainer > button > span {
  font-weight: 400;
  text-decoration: underline;
  letter-spacing: 0.1px;
  font-family: Roboto;
  font-size: 14px;
  vertical-align: text-top;
  line-height: 16px;
  padding-left: 0.5rem;
  letter-spacing: 0.05rem;
  text-align: right;
}

